import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import { AuthService } from './auth.service'
import { LocationService } from './location.service'
import { ExploreService } from './explore.service'
import { SocialService } from './social.service'
import { UtilsService } from './utils.service'
import { WizardService } from './wizard.service'
import { PaymentsService } from './payments.service'
import { AdvisorService } from './advisor.service'
import { InboxService } from './inbox.service'
import { InsuranceService } from './insurance.service'
export default class Api {
  client: AxiosInstance

  auth: AuthService
  location: LocationService
  explore: ExploreService
  social: SocialService
  utils: UtilsService
  wizard: WizardService
  payments: PaymentsService
  advisor: AdvisorService
  inbox: InboxService
  insurance: InsuranceService

  constructor() {
    // build client
    this.client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_BASE,
    })

    // initialize service classes
    this.auth = new AuthService(this.client)
    this.location = new LocationService(this.client)
    this.explore = new ExploreService(this.client)
    this.social = new SocialService(this.client)
    this.utils = new UtilsService(this.client)
    this.wizard = new WizardService(this.client)
    this.payments = new PaymentsService(this.client)
    this.advisor = new AdvisorService(this.client)
    this.inbox = new InboxService(this.client)
    this.insurance = new InsuranceService(this.client)
  }

  // for those scenarios where token is not available in AuthContext like session resumption
  authorize(token: string) {
    this.client.defaults.headers['Authorization'] = `Bearer ${token}`
  }
}
