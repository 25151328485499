import { AxiosInstance } from 'axios'

interface SpainInsuranceRequest {
  firstName: string
  lastName: string
  email: string
  dob: string
  spouse: number
  children: number
  pets: number
  country: string
  city: string
  arrivalDate: string
  kind: string[]
}

export class InsuranceService {
  constructor(private instance: AxiosInstance) {}

  requestSpainInsurance = (body: SpainInsuranceRequest) => {
    return this.instance
      .post('/insurance/spain-quote', body)
      .then((response) => response.data)
  }
}
